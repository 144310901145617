import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import MapComponent from '../components/mapComponent';
import locationIcon from '../images/current-location-icon-dark-01.svg';
import mailIcon from '../images/envelope-dark-01.svg';
import phoneIcon from '../images/phone.svg';
import axios from 'axios';
import Layout from "../components/layout.tsx";
import ContactImage from "../components/contactImage.tsx";
import animation from '../components/animation.module.css';

import { 
  ContactContainer,
  Copyright,
  LeftContainer,
  RightContainer,
  FormContainer,
  MapContainer,
  ContactInfoContainer,
  ContactInfo,
  ContactInfoItem,
  InfoIcon,
  ActualForm,
  Label,
  Input,
  TextArea,
  ButtonContainer,
  Submit,
  OutgoingMessage,
  InfoLink
} from "../styles/ContactStyles.ts";

export default class ContactPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  state = {
    name: "",
    email: "",
    company: "",
    body: "",
    formErrors: {name: "", email: "", body: ""},
    nameValid: false,
    emailValid: false,
    companyValid: false,
    bodyValid: false,
    showForm: true,
    showOutgoingMessage: false,
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    axios.post('/contact_email', this.state)
    .then(function(response) {
      console.log(response);
    })
    .catch(function(error) {
      console.log(error);
    });

    this.setState({showForm: false, showOutgoingMessage: true})

  }

  render() {
    return (
    <div>
      <Layout>
        <Helmet>
          <title>Contact - Abside Networks</title>
        </Helmet>
        <ContactContainer>
          <LeftContainer>
            <MapComponent />
          </LeftContainer>
          <RightContainer>
            <ContactInfoContainer>
              <ContactInfo>
                <ContactInfoItem><InfoIcon src={mailIcon} />General Inquiries: <InfoLink href="mailto:contact@abside-networks.com">contact@abside-networks.com</InfoLink></ContactInfoItem>
                <ContactInfoItem><InfoIcon src={mailIcon} />Media Inquiries: <InfoLink href="mailto:media@abside-networks.com">media@abside-networks.com</InfoLink></ContactInfoItem>
                <ContactInfoItem><InfoIcon src={locationIcon} />Office: <InfoLink href="https://www.google.com/maps/place/36+Knox+Trail,+Acton,+MA+01720/@42.4463465,-71.4281361,11z" target="_blank" rel="noopener noreferrer"> 36 Knox Trail, Acton, MA 01720</InfoLink></ContactInfoItem>
              </ContactInfo>
            </ContactInfoContainer>
            <ActualForm onSubmit={this.handleSubmit} className={this.state.showForm ? '' : animation.hideForm}>
              <Label>
                Name
                <Input 
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />
              </Label>
              <Label>
                Email
                <Input 
                  type="email" 
                  name="email" 
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </Label>
              <Label>
                Company
                <Input 
                  type="text" 
                  name="company" 
                  value={this.state.company}
                  onChange={this.handleInputChange}
                />
              </Label>
              <Label>
                Message
                <TextArea
                  type="text" 
                  name="body"
                  value={this.state.body}
                  onChange={this.handleInputChange}
                />
              </Label>
              <ButtonContainer>
                <Submit type="submit">SUBMIT</Submit>
              </ButtonContainer>
            </ActualForm>
            <OutgoingMessage className={this.state.showOutgoingMessage ? animation.unhide: ''}>Thank you, we'll get back to you shortly.</OutgoingMessage>
          </RightContainer>
        </ContactContainer>
      </Layout>
    </div>
  )}
};

import Styled from 'styled-components';

export const ContactContainer = Styled.div`
  display: block;
  position: relative;
  width: 100%;
  overflow: auto;
  @media (max-width: 700px) {
    height: auto;
    overflow-y: auto;
  }
`;

export const Title = Styled.h1`
  display: block;
  font-family: 'Helvetica Neue Roman', sans-serif;
  font-size: 26px;
  color: #494956;
  text-align: center;
  width: 50%;
  margin: 0 auto;
`;

export const Subtitle = Styled(Title)`
  font-size: 16px;
  padding-top: 35px;
`;


export const Copyright = Styled(Subtitle)`
  padding-bottom: 15px;
  font-size: 13px;
  color: #444;
  white-space: nowrap;
  text-align: center;
`;


export const LeftContainer = Styled.div`
  display: block;
  float: left;
  width: 48%;
  margin-top: 100px;
  @media (max-width: 700px) {
    width: 85%;
    position: relative;
    margin-left: 7.5%;
  }
`;

export const RightContainer = Styled.div`
  display: block;
  float: left; 
  width: 52%;
  padding-left: 4%;
  margin-top: 120px;
  @media (max-width: 700px) {
    width: 95%;
    position: relative;
    padding-left: 7.5%;
    margin-top: 40px;
  }
`;

export const FormContainer = Styled.div`
  display: block;
  margin-top: 125px;
  float: right;
  width: 47%;

  @media (max-width: 760px) and (orientation: portrait) {
    width: 100%;
    margin-top: 60px;

  }
`;

export const MapContainer = Styled.div`
  display: block;
  margin-top: 125px;
  float: left;
  width: 47%;
  height: 100vh;

  @media (max-width: 760px) and (orientation: portrait) {
    display: none;
  }
`;

export const ContactInfoContainer = Styled.div`
  width: 100%;
  float: left;
  height: auto;
`;

export const ContactInfo = Styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ContactInfoItem = Styled.li`
  display: block;
  list-style: none;
  color: #494956;
  float: left;
  font-family: "Helvetica Neue Roman", sans-serif;
  font-weight: 300;
  font-size: 16px;
  width: 100%;
  margin: 0 !important;
`;

export const InfoIcon = Styled.img`
  width: 30px;
  display: block;
  float: left;
  padding-right: 10px;
  margin-top: -1px;
  padding-top: 5px;
`;

export const ActualForm = Styled.form`
  height: 100%;
  display: block;
`;

export const Label = Styled.label`
  display: block;
  float: left;
  width: 100%;
  position: relative;
  clear: both;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: "Helvetica Neue Roman", sans-serif;
  font-size: 16px;
  color: #494956;

  @media (min-width: 800px) { 
    font-size: 16px;
  }
`;

export const Input = Styled.input`
  display: block;
  background-color: #F5F5F5;
  width: 100%;
  position: relative;
  clear: both;
  color: #494956;
  border-color: #F5F5F5;
  border-radius: 3px;
  border-width: 1px;
  padding-top: 8px;
  padding-bottom: 7px;
  margin-top: 10px;
  font-family: "Helvetica Neue Light", sans-serif;
  font-size: 14px;
  height: 40px;
  outline: none;
  border-style: solid;

  :focus {
    border-color: #F5F5F5;
    outline: none !important;
  }

  @media (min-width: 940px) {
    padding-left: 15px;
  }

  @media (max-width: 760px) and (orientation: portrait) {
    padding-left: 10px;
    width: 97%;
  }
`;

export const TextArea = Styled.textarea`
  border-style: solid;
  color: #494956;
  display: block;
  background-color: #F5F5F5;
  width: 100%;
  position: relative;
  clear: both;
  border-color: #F5F5F5;
  border-radius: 3px;
  border-width: 1px;
  padding-top: 15px;
  padding-bottom: 7px;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Helvetica Neue Light", sans-serif;
  resize: none;
  height: calc(100vh - 750px);
  min-height: 250px;

  :focus {
    border-color: #F5F5F5;
    outline: none !important;
  }

  @media (min-width: 940px) {
    padding-left: 15px;
  }

 @media (max-width: 760px) and (orientation: portrait) {
    width: 96.5%;
    height: 300px;
    padding-left: 10px;
  }
`;

export const ButtonContainer = Styled.div`
  width: 100%;
  height: 45px;
  display: block;
  clear: both;
  position: relative;
  top: -60px;

  @media (max-width: 700px) {
    width: 96.5%;
  }
`;

export const Submit = Styled.button`
  display: block;
  background-color: #C6E6E6;
  clear: both;
  border-color: #C6E6E6;
  border-radius: 0 0 3px 3px;
  border-width: 1px;
  text-align: center;
  color: white;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-family: "Helvetica Neue Roman", sans-serif;
  width: 100%;
  transition: background-color .3s, border-color: .3s;

  height: 45px;  
  :hover {
    cursor: grab;
    background-color: #3F5857;
    border-color: #3F5857;
  }

  :focus {
    outline: 0;
  }
`;

export const OutgoingMessage = Styled.h2`
  font-family: "Helvetica Neue Light", sans-serif;
  font-size: 16px;
  margin-top: -400px;
  position: absolute;
  text-align: center;
  font-weight: 300;
  padding-left: 60px;
  opacity: 0;
  color: #494956 !important;
`;

export const InfoLink = Styled.a`
  text-decoration: none;
  color: #494956;
  transition: color .33s;

  :hover {
    color: #000;
    text-decoration: underline;
    transition: color .33s;
  }
`;

export const BannerContainer = Styled.div`
  display: block;
  margin-top: -20px;
`;

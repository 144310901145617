import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { mapStyles } from '../styles/map_styles';
import currentLocationIcon from '../images/current-location-icon-dark-01.svg';

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyCz7S7Rnnju1NoGsyaD6jECzHrCbkX6xcw",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `calc(100vh - 140px)` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: 42.3889652, lng: -71.2747103 }}
    options={{ styles: mapStyles, fullscreenControl: false, mapTypeControl: false, streetViewControl: false}}
  >
    <Marker position={{ lat: 42.446291, lng: -71.426033 }} onClick={props.onMarkerClick} icon={{url: currentLocationIcon, scaledSize: new window.google.maps.Size(50,75)}} />
  </GoogleMap>
);

export default MyMapComponent;